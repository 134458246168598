<template>
  <div class="BaseReveal">
    <button
      v-if="!showContent"
      class="cta--default"
      @click="revealContent"
    >
      Read More
    </button>
    <div v-if="showContent">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showContent: false
    }
  },
  methods: {
    revealContent () {
      this.showContent = true
    }
  }
}
</script>
