/*
|--------------------------------------------------------------------------
| Entry File
|--------------------------------------------------------------------------
|
| This is the main entry file used by Webpack. As such, we use it to
| include CSS as well as the usual JS. The order is important as
| CSS rules ar affected by specificity. All styles loaded in
| App.vue and its child files will of course be included.
|
*/

import Vue from 'vue'

import WebFont from 'webfontloader'
import './styles/resources.scss'
import App from './App.vue'

Vue.config.productionTip = false

WebFont.load({
  google: {
    families: [
      'Source Sans Pro:400',
      'Open Sans:400,600,700'
    ]
  }
})

new Vue(App).$mount('#app')
